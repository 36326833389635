export const cva =
  <T>(base?: ClassValue, config?: Config<T>) =>
  (props?: Props<T>) => {
    const { className, ...specifiedProps } = Object(props) as Props<T>
    const allProps = {
      ...config?.defaultVariants,
      ...Object.fromEntries(Object.entries(specifiedProps).filter(([_, value]) => value != null)),
    }

    return cx(
      // base classes
      base,

      // variant classes fulfilled by props
      Object.entries(allProps).map(
        ([key, value]) => config?.variants?.[key]?.[value as string] || [],
      ),

      // compound variant classes fulfilled by compound props
      config && Array.isArray(config.compoundVariants)
        ? config.compoundVariants
            .filter((cv) =>
              Object.entries(cv).every(([k, v]) => k === "className" || allProps[k] === v),
            )
            .map((cv) => cv.className)
        : [],

      // instance classes
      className,
    )
  }

export const cx = (...classes: ClassValue[]): string =>
  // @ts-expect-error infinite type instantiation is excessively deep
  classes.flat(Infinity).filter(Boolean).join(" ")

// #region Types

export type ClassValue = string | number | boolean | ClassValue[] | null | undefined

export type VariantProps<Component> = Component extends (props?: infer T) => string
  ? Omit<T, "className">
  : never

// #endregion

// #region Internal cva types

type ConfigSchema = Record<string, Record<string, ClassValue>>

type Config<T> = T extends ConfigSchema
  ? {
      variants?: T
      defaultVariants?: ConfigVariants<T>
      compoundVariants?: (T extends ConfigSchema
        ? (ConfigVariants<T> | ConfigVariantsMulti<T>) & ClassProp
        : ClassProp)[]
    }
  : never

type ConfigVariants<T extends ConfigSchema> = {
  [Variant in keyof T]?: StringToBoolean<keyof T[Variant]>
}

type ConfigVariantsMulti<T extends ConfigSchema> = {
  [Variant in keyof T]?: StringToBoolean<keyof T[Variant]> | StringToBoolean<keyof T[Variant]>
}

type StringToBoolean<T> = T extends "true" | "false" ? boolean : T

type ClassProp = {
  className?: ClassValue
}

type Props<T> = T extends ConfigSchema ? ConfigVariants<T> & ClassProp : ClassProp

// #endregion
